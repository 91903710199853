<template>
  <div>
    <price-action />
  </div>
</template>

<script>
import PriceAction from "../components/PriceAction.vue";

export default {
  name: "PriceAdd",
  components: { PriceAction },
};
</script>
